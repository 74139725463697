html, body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	background: #FAFAFA;
	/* font-family: 'Architects Daughter', cursive; */
	font-weight: 400;
	color: #444;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: var(--honeydew);
}

/* html.fslightbox-open {
	overflow: auto!important;
} */

body {
	/* overflow: auto!important; */
}

* {
	box-sizing: border-box;
}


/* CSS HEX */
:root {
	--honeydew: #cbe2d5ff;
	--black-olive: #414a45ff;
	--hunter-green: #2e5942ff;
	--amazon: #397052ff;
	--medium-aquamarine: #57de93ff;
	--footer-green: #86968D;
	--section-brighter: #DDE4E0;
	--section-bright: #D3DAD6;
	--section-dark: #86968D;
	--section-darker: #69756e;
}


/* CSS HSL */
--medium-spring-green: hsla(153, 100%, 66%, 1);
--mint: hsla(153, 39%, 50%, 1);
--illuminating-emerald: hsla(158, 39%, 39%, 1);
--feldgrau: hsla(158, 13%, 38%, 1);
--feldgrau-2: hsla(149, 15%, 35%, 1);

/* SCSS HEX */
$medium-spring-green: #50ffb1ff;
$mint: #4fb286ff;
$illuminating-emerald: #3c896dff;
$feldgrau: #546d64ff;
$feldgrau-2: #4d685aff;

/* SCSS HSL */
$medium-spring-green: hsla(153, 100%, 66%, 1);
$mint: hsla(153, 39%, 50%, 1);
$illuminating-emerald: hsla(158, 39%, 39%, 1);
$feldgrau: hsla(158, 13%, 38%, 1);
$feldgrau-2: hsla(149, 15%, 35%, 1);

/* SCSS RGB */
$medium-spring-green: rgba(80, 255, 177, 1);
$mint: rgba(79, 178, 134, 1);
$illuminating-emerald: rgba(60, 137, 109, 1);
$feldgrau: rgba(84, 109, 100, 1);
$feldgrau-2: rgba(77, 104, 90, 1);

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-right: linear-gradient(90deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-bottom: linear-gradient(180deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-left: linear-gradient(270deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-top-right: linear-gradient(45deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-bottom-right: linear-gradient(135deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-top-left: linear-gradient(225deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-bottom-left: linear-gradient(315deg, #50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);
$gradient-radial: radial-gradient(#50ffb1ff, #4fb286ff, #3c896dff, #546d64ff, #4d685aff);

.container.is-fluid {
	padding: 0!important;
}

.container {
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.6s ease-out, transform 1.2s ease-out;
	/* will-change: opacity, visibility; */
  }
  
  .container.fade-in-left {
	transform: translateX(-10vw);
  }
  
  .container.fade-in-right {
	transform: translateX(10vw);
  }
  
  .container.fade-in-bottom {
	transform: translateY(20vh);
  }
  
  .container.fade-in-default {
	transform: none;
  }
  
  .container.is-visible {
	opacity: 1;
	transform: none;
	visibility: visible;
  }
  
  .section.color-brighter {
	background-color: var(--section-brighter);
  }
  
  .section.color-bright {
	background-color: var(--section-bright);
  }
  
  .section.color-default {
	background-color: none;
  }
  
  .section.color-dark {
	background-color: var(--section-dark);
  }
  
  .section {
	overflow-x: hidden;
  }
  
  .container.is-fluid {
	padding: 0!important;
  }
  
  .header {
	  height: 100vh;
	  height: calc(var(--vh, 1vh) * 100);
	  width: 100%;
	  overflow: hidden;
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  position: relative;
  }
  
  .image-container {
	  position: absolute;
	  height: 100%;
	  width: 100%;
	  filter: blur(2px);
	  object-fit: cover;
	  transform: scale(1.1);
  }
  
  .image-container img {
	height: 100%;
	  width: 100%;
	  object-fit: cover;
  }
  
  h1 {
	  z-index: 1;
	  font-family: 'Architects Daughter', cursive;
	  font-size: 6rem;
	  color: #fff;
	  text-align: center;
	  user-select: none;
  
	  animation: h1animation .6s ease-in;
	  /* animation-delay: 2s; */
  }
  
  @keyframes h1animation {
	  0% {
		  opacity: 0;
	  }
	  100% {
		  opacity: 1;
	  }
  }
  
  .scrolldown {
	  position: absolute;
	bottom: 40px;
	left: 50%;
	width: 24px;
	height: 24px;
	margin-left: -12px;
	border-left: 2px solid #fff;
	border-bottom: 2px solid #fff;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	-webkit-animation: sdb05 1.5s infinite;
	animation: sdb05 1.5s infinite;
	box-sizing: border-box;
  }
  @-webkit-keyframes sdb05 {
	0% {
	  -webkit-transform: rotate(-45deg) translate(0, 0);
	  opacity: 0;
	}
	50% {
	  opacity: 1;
	}
	100% {
	  -webkit-transform: rotate(-45deg) translate(-20px, 20px);
	  opacity: 0;
	}
  }
  @keyframes sdb05 {
	0% {
	  transform: rotate(-45deg) translate(0, 0);
	  opacity: 0;
	}
	50% {
	  opacity: 1;
	}
	100% {
	  transform: rotate(-45deg) translate(-20px, 20px);
	  opacity: 0;
	}
  }
  
  .scrollLabel {
	position: absolute;
	bottom: 60px;
	left: 50%;
	transform: translateX(-50%);
	color: #fff;
	font-weight: bold;
  }
  
  .grid-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
	column-gap: 10px;
	row-gap: 10px;
  }
  
  .grid-container > div {
	display: flex;
	justify-content: center;
  }
  
  .image-wrapper img {
	cursor: pointer;
  }