.header {
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
	width: 100%;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
}

.image-container {
	position: absolute;
	height: 100%;
	width: 100%;
	filter: blur(2px);
	object-fit: cover;
	transform: scale(1.1);
}

.image-container img {
  height: 100%;
	width: 100%;
	object-fit: cover;
}

h1 {
	z-index: 1;
	font-family: 'Architects Daughter', cursive;
	font-size: 6rem;
	color: #fff;
	text-align: center;
	user-select: none;

	animation: h1animation .6s ease-in;
	/* animation-delay: 2s; */
}

@keyframes h1animation {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.scrolldown {
	position: absolute;
  bottom: 40px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb05 1.5s infinite;
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}

.scrollLabel {
  position: absolute;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-weight: bold;
}

