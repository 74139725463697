.container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s ease-out, transform 1.2s ease-out;
  /* will-change: opacity, visibility; */
}

.container.fade-in-left {
  transform: translateX(-10vw);
}

.container.fade-in-right {
  transform: translateX(10vw);
}

.container.fade-in-bottom {
  transform: translateY(20vh);
}

.container.fade-in-default {
  transform: none;
}

.container.is-visible {
  opacity: 1;
  transform: none;
  visibility: visible;
}

.section.color-brighter {
  background-color: var(--section-brighter);
}

.section.color-bright {
  background-color: var(--section-bright);
}

.section.color-default {
  background-color: none;
}

.section.color-dark {
  background-color: var(--section-dark);
}

.section.color-darker {
  background-color: var(--section-darker);
}

.section {
  overflow-x: hidden;
}

.container.is-fluid {
  padding: 0!important;
}