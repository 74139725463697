.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    column-gap: 10px;
    row-gap: 10px;
}

.grid-container > div {
    display: flex;
    justify-content: center;
}

.image-wrapper img {
    cursor: pointer;
}